/*
 * Copyright (C) Ethical Recruitment Solutions LLP - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <tom.l.hewett@gmail.com>, Jan 2019
 * ========================================================================
 */

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

export function login(user) {
    return { type: LOGIN, user }
}

export function logout() {
    return { type: LOGOUT }
}