/*
 * Copyright (C) Ethical Recruitment Solutions LLP - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <tom.l.hewett@gmail.com>, Jan 2019
 * ========================================================================
 */

export const SET_TOKEN = 'SET_ACCESS_TOKEN'

export function setToken(token) {
    return {
        type: SET_TOKEN,
        token
    }
}