/*
 * Copyright (C) Ethical Recruitment Solutions LLP - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <tom.l.hewett@gmail.com>, Jan 2019
 * ========================================================================
 */

import { combineReducers } from 'redux'
import user from './user/reducers'
import session from './session/reducers'

const app = combineReducers({
    user,
    session
})

export default app