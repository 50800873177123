/*
 * Copyright (C) Ethical Recruitment Solutions LLP - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <tom.l.hewett@gmail.com>, Jan 2019
 * ========================================================================
 */

import React, { PureComponent } from 'react'
import dom from 'react-dom'
import Loadable from 'react-loadable'
import ErrorBoundary from 'utils/ErrorBoundary'
import { BrowserRouter as Router } from 'react-router-dom'
import { Dimmer, Loader } from 'semantic-ui-react'
import { GenericError } from 'views/errors'
import { Provider } from 'react-redux'
import { store, hydrate } from 'store/store.js'
import { AxiosProvider } from 'react-axios'
import { setAuthorizationHeaders } from 'utils/auth'
import axios from 'axios'
import config from 'config'
import 'semantic-ui-css/semantic.min.css'
import 'resources/ethicore.css'

const LoadableApp = Loadable({
    loader: () => import('./App'),
    loading: function loadingApp(props) {
        if (props.error) {
            return (
                <GenericError
                    header={props.error.toString()}
                    stacktrace={props.error.stack}
                />
            )
        }

        return (
            <ErrorBoundary>
                <div style={
                    {
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#fff'
                    }
                }>
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                </div> 
            </ErrorBoundary>
        )
    }
})

class App extends PureComponent {

    componentDidMount() {
        const serializedState = localStorage.getItem('state')
        if (serializedState) {
            store.dispatch(hydrate(JSON.parse(serializedState)))
            const state = JSON.parse(serializedState)
            setAuthorizationHeaders(state.session?.token || null)
        }
    }

    render() {
        const axiosInstance = axios.create({
            baseURL: config.api.baseUrl,
            timeout: 2000,
            headers: {}
        });

        axiosInstance.defaults.timeout = 180000

        return (
            <ErrorBoundary>
                <Provider store={store} >
                    <AxiosProvider instance={axiosInstance}>
                        <Router>
                            <LoadableApp />
                        </Router>
                    </AxiosProvider>
                </Provider>
            </ErrorBoundary>
        )
    }
}

const root: ?Element = document.getElementById('root');

if (root != null) {
    dom.render(<App />, root)
}