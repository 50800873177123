/*
 * Copyright (C) Ethical Recruitment Solutions LLP - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <tom.l.hewett@gmail.com>, Jan 2019
 * ========================================================================
 */

import { SET_TOKEN } from './actions'
import { HYDRATE } from 'store/store.js'

export default function session(state = {}, action) {
    switch (action.type) {
        case SET_TOKEN:
            return Object.assign({}, state, {
                token: action.token
            })
        case HYDRATE:
            return Object.assign({}, state, {
                token: action.serializedState.session.token
            })
        default:
            return state
    }
}