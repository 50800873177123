/*
 * Copyright (C) Ethical Recruitment Solutions LLP - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <tom.l.hewett@gmail.com>, Jan 2019
 * ========================================================================
 */

// @flow

import { store } from 'store/store.js'
import { setToken } from 'store/session/actions'
import { logout } from 'store/user/actions'
import type { User } from 'flowtypes/models'
import axios from 'axios'

export function handleLogout() {
    store.dispatch(setToken())
    store.dispatch(logout())
    setAuthorizationHeaders(null)
}

export function setAuthorizationHeaders(token: ?string = null) {
    if(token) {
        axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['authorization'];
    }
}

export function hasPermissionsToEditUser(editableUser: User, ctxUser: User): boolean {
    if (ctxUser.type === 'superadmin') {
        return true
    }

    if (ctxUser.type === 'admin') {
        if (ctxUser.id === editableUser.id) {
            return true
        }
        if (editableUser.type === 'default') {
            return true
        }
    }

    return false
}