/*
 * Copyright (C) Ethical Recruitment Solutions LLP - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <tom.l.hewett@gmail.com>, Jan 2019
 * ========================================================================
 */

import React from 'react'
import { Message, Divider } from 'semantic-ui-react'

type Props = {
    header: string,
    stacktrace: string
}

const GenericError = (props: Props) => {
    return (
        <div>
            <Message error>
                <Message.Header>{ props.header }</Message.Header>
                <Divider />
                <pre>{ props.stacktrace }</pre>
            </Message>
        </div>
    )
}

export default GenericError