/*
 * Copyright (C) Ethical Recruitment Solutions LLP - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <tom.l.hewett@gmail.com>, Jan 2019
 * ========================================================================
 */

import { LOGIN, LOGOUT } from './actions'
import { HYDRATE } from 'store/store.js'

export default function user(state = null, action) {
    switch (action.type) {
        case LOGIN:
            return action.user
        case LOGOUT:
            return null
        case HYDRATE:
            return action.serializedState.user
        default:
            return state
    }
}