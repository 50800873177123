/*
 * Copyright (C) Ethical Recruitment Solutions LLP - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <tom.l.hewett@gmail.com>, Jan 2019
 * ========================================================================
 */

import React, { PureComponent, type Node } from 'react'
import { Message, Divider } from 'semantic-ui-react'

type ReactErrorInfo = {
    componentStack: string,
}

type Props = {
    children: Node
}

type State = {
    hasError: boolean,
    info: ?ReactErrorInfo,
    error: ?Error
}

export default class ErrorBoundary extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            hasError: false,
            info: null,
            error: null
        }
    }

    componentDidCatch(error: Error, info: ReactErrorInfo) {
        console.error('Error caught by ErrorBoundary')
        this.setState({ hasError: true, info, error})
    }   

    render() {
        const { hasError, error, info } = this.state

        if (hasError) {
            return (
                <div>
                    <Message error>
                        <Message.Header>{ error ? error.toString() : 'Error' }</Message.Header>
                        <Divider />
                        {
                            info && info.componentStack && (
                                <pre>{ info.componentStack }</pre>
                            )   
                        }
                    </Message>
                </div>
            )
        }

        return this.props.children
    }
}