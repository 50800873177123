/*
 * Copyright (C) Ethical Recruitment Solutions LLP - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <tom.l.hewett@gmail.com>, Jan 2019
 * ========================================================================
 */

import { createStore, applyMiddleware } from 'redux'
import app from './reducers'
import { mirrorStateToLocalStorage } from './middleware' 

export const HYDRATE = 'HYDRATE'

export function hydrate(serializedState) {
    return {
        type: HYDRATE,
        serializedState
    }
}

export const store = createStore(
    app,
    applyMiddleware(mirrorStateToLocalStorage)
)

export type Dispatch = typeof store.dispatch