/*
 * Copyright (C) Ethical Recruitment Solutions LLP - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <tom.l.hewett@gmail.com>, Jan 2019
 * ========================================================================
 */

export function mirrorStateToLocalStorage({ getState }) {
    return next => action => {
        const returnValue = next(action)
        localStorage.setItem('state', JSON.stringify(getState()))
        return returnValue
    }
}